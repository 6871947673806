exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-motivations-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/content/motivations.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-motivations-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/content/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-release-notes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/content/release-notes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-release-notes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-terms-of-use-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/content/terms-of-use.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-terms-of-use-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-user-guide-apple-watch-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/content/user-guide-apple-watch.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-user-guide-apple-watch-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-user-guide-iphone-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/content/user-guide-iphone.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-user-guide-iphone-mdx" */)
}

